import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import NewFaq from "./FaqTowColumn";
export default function CompanySetup() {
  const faqItems = [
    {
      question:
        "What do ‘mainland’ and free zone’ mean in Dubai’s business landscape?",
      ans: `In Dubai, ‘mainland’ refers to the areas outside the free zones. Companies established on the mainland are issued licences by the Department of Economy and Tourism. 

On the other hand, ‘free zones’ in Dubai are specially designated areas, often sector-specific. Each free zone operates under its own rules and regulations for licensing and registration.`,
    },
    {
      question:
        "Do mainland and free zone companies in Dubai differ in their Emiratisation requirements?",
      ans: `Yes, it is mandatory for mainland companies that a minimum of 2% of their skilled workforce consists of Emirati nationals.

Conversely, businesses operating within free zones have no Emiratisation requirements.`,
    },
    {
      question:
        "What are the jurisdictions for conducting business in Dubai’s mainland and free zones?",
      ans: `In Dubai's mainland, there are no territorial restrictions on business activities. Companies here can operate both within and outside the UAE, including the broader GCC region. This includes the free zones.

Meanwhile, free zones in Dubai primarily focus on business activities outside the UAE. However, six specific free zones offer dual licences, which allow businesses to engage in both onshore and offshore activities.`,
    },
    {
      question:
        "What are the types of legal entities available for businesses in Dubai?",
      ans: `Businesses setting up on the mainland have a diverse range of legal forms to choose from, aligning with their intended business activities. These options include General Partnerships, Limited Partnerships, Limited Liability Companies, Single Owner Limited Liability Companies, Private Joint Stock Companies, Single Owner Private Joint Stock Companies, Public Joint Stock Companies, Civil Companies, and Sole Proprietorships.

      Free zones also offer different legal forms or company structures, although the most common ones include Free Zone Establishments, Free Zone Companies, Public Limited Liability Companies, Private Limited Liability Companies, and branches of local or international companies.`,
    },
    {
      question:
        "What are the ownership options when comparing mainland and free zones in Dubai?",
      ans: `In the mainland, foreign investors are permitted full ownership, according to the Federal Decree-Law No. 26 of 2020, which amended Federal Law No. 2 of 2015 on Commercial Companies. This means that foreign investors can have 100% ownership in more than 1,000 commercial and industrial activities. However, this excludes economic activities with a strategic impact, which relate to certain specific sectors.

      In free zones, foreign investors can have 100% ownership of all companies.`,
    },
    {
      question:
        "What are the requirements related to a physical office or presence for businesses, depending on where they set up?",
      ans: `In Dubai's mainland, businesses are required to have a physical workspace with a minimum size of 100 sq. ft. There are no restrictions on the location where the company can rent or buy office premises.

      Conversely, there are no compulsory physical office space requirements for free zone companies. Free zones provide the option of virtual and flexible desk solutions for business licences. However, if businesses choose to lease office space, it must be within their free zone.`,
    },
    {
      question:
        "What are the tax implications for businesses operating in Dubai’s mainland vs the free zones?",
      ans: `In the mainland, businesses face a 5% Value Added Tax (VAT) and a 9% Corporate Tax, applicable to those with profits exceeding AED 375,000 (USD 100,000). The advantage is that there is no income tax for businesses in the mainland. 

     There are significant tax benefits for businesses that operate in the free zones. These include 100% exemptions from corporate tax, customs duty, VAT, and income tax. It also allows for repatriation of capital and profits from the business.`,
    },
    {
      question:
        "What are the requirements for minimum share capital for a business?",
      ans: `If your company is set up on the mainland, there is no standard requirement for minimum share capital. The amount is determined by the legal structure chosen by the business.

     When it comes to free zones in Dubai, the minimum share capital requirement varies from one free zone to another.`,
    },
    {
      question:
        "Are there any differences in the audit requirements between Dubai’s mainland and free zones?",
      ans: `No, there are no differences in the audit requirements between Dubai’s mainland and free zones. In both, firms are required to undergo annual audits.`,
    },
    {
      question:
        "Are there any restrictions to obtain visas for employees in mainland companies vs free zones?",
      ans: `There are no restrictions on the number of visas that can be obtained in a company set up on the mainland, as long as they are in line with regulatory frameworks regarding the size of the premise and the business activity.

      However, most free zone authorities in Dubai impose a quota or limit on the number of visas that can be issued to each business. Companies can increase their visa quota either by relocating to larger offices or by applying for an increase in the visa quota, subject to the approval of the free zone authority.`,
    },
  ];
  return (
    <>
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Company set-up options in Dubai
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            You can set up a company on the 'mainland' or in a free zone –
            here's a comparison of both options.
            <br />
            <br />
            When setting up a company in Dubai, the first major decision you
            have to make is where to establish your business: within one of
            Dubai's many free zones, or on the "mainland". It's important to
            make an informed choice regarding company jurisdiction, and to
            understand what "mainland" and "<fr></fr>ee zone" mean.
          </p>
        </div>
      </div>
      <div className="resource-container">
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://files.oaiusercontent.com/file-N8omgc9PegaffTBaRliVcWqd?se=2024-09-04T05%3A46%3A02Z&sp=r&sv=2024-08-04&sr=b&rscc=max-age%3D604800%2C%20immutable%2C%20private&rscd=attachment%3B%20filename%3D97369ccb-af50-4971-bfb1-c0f0107df39b.webp&sig=/Cf4bw6/W9BzqAno4S5Rx4ASRi8tp//cYJd3Zuk2nWg%3D"
              alt="img"
              style={{ maxWidth: "80%", height: "80%" }}
            />

            <h5 style={{ margin: "20px 0" }}>Mainland Dubai</h5>
            <p>
              Areas outside the free zones: the Dubai Department of Economy &
              Tourism (DET) manages registration and licensing of mainland
              companies.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.seaandjob.com/wp-content/uploads/2022/12/sohar-port_3.png"
              alt="img"
              style={{ maxWidth: "80%", height: "80%" }}
            />

            <h5 style={{ margin: "20px 0" }}>Free zones</h5>
            <p>
              Designated areas within Dubai, with benefits including custom
              duties and tax exemptions. Each zone has its own rules,
              regulations and authorities.
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "10px",
        }}
      ></div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <p>
            Free zones are usually tailored to specific industries or sectors,
            and typically companies within free zones conduct business outside
            the UAE. Mainland companies can trade within and outside the UAE,
            and are subject to standard Dubai laws and regulations.
            <br />
            <br />
            To help you make an informed decision, we’ve compared the two
            business set-up options below, highlighting criteria that might
            affect your decision.
          </p>
        </div>
      </div>

      <div className="row" style={{ width: "80%", margin: "auto" }}>
        <NewFaq
          title="What are the differences between mainland and free zones in Dubai?"
          colNo={2}
          faqItems={faqItems}
        />
      </div>
      <div className="container my-5">
            <h2 className="mb-4">More from Dubai for business</h2>
            <p className="mb-5">Browse information on Dubai's business landscape and how to start or move your business to Dubai.</p>
            <div className="row">
                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="card h-100">
                        <img src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765" className="card-img-top" alt="Mainland companies" />
                        <div className="card-body">
                            <h5 className="card-title" style={{background:"none",boxShadow:"none"}}>Mainland companies</h5>
                            <p className="card-text" style={{background:"none",boxShadow:"none"}}>If you're looking to trade within the UAE, or prefer not to be within a free zone, establishing a mainland company is the path.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="card h-100">
                        <img src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765" className="card-img-top" alt="Free zone companies" />
                        <div className="card-body">
                            <h5 className="card-title" style={{background:"none",boxShadow:"none"}}>Free zone companies</h5>
                            <p className="card-text" style={{background:"none",boxShadow:"none"}}>Learn more about Dubai's free zones and the process of setting up a company within them.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="card h-100">
                        <img src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765" className="card-img-top" alt="Employees, administration and logistics" />
                        <div className="">
                            <h5 className="card-title" style={{background:"none",boxShadow:"none"}}>Employees, Regulations, legal compliance and taxation</h5>
                           
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="card h-100">
                        <img src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765" className="card-img-top" alt="Employees, administration and logistics" />
                        <div className="">
                            <h5 className="card-title" style={{background:"none",boxShadow:"none"}}>Support for start-ups and SMEs</h5>
                            <p className="card-text" style={{background:"none",boxShadow:"none"}}>Getting the right help is crucial for a new company – and Dubai has plenty of support options.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-4">
                    <div className="card h-100">
                        <img src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765" className="card-img-top" alt="Employees, administration and logistics" />
                        <div className="">
                            <h5 className="card-title" style={{background:"none",boxShadow:"none"}}>Employees, administration and logistics</h5>
                            <p className="card-text" style={{background:"none",boxShadow:"none"}}>All the information you need about visas, bank accounts, employment, and business premises.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}
