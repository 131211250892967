import React from 'react';
import homeOfficeImg from "../assets/images/home-office.jpg";

export default function GetAJob() {
  return (
    <>
    <div className="container-xxl" style={{ padding: "7% 7% 0 7%" }}>
      <div>
        <h1 style={{ marginBottom: "20px", color: "black" }} className="display-6">
          Working in Dubai: A Comprehensive Guide
        </h1>
        <p>
          As a leading business epicentre, Dubai offers exceptional opportunities
          for skilled professionals to grow their careers.
        </p>
      </div>
    </div>
  
    <div style={{ marginTop: "40px" }}>
      <img
        src={homeOfficeImg}
        alt="Working in Dubai banner"
        width={"100%"}
        style={{ borderBottomRightRadius: "137px" }}
      />
    </div>
  
    <div
      style={{
        background: "#ececed",
        padding: "30px 0",
        marginBottom: "40px",
        width: "90%",
        float: "inline-end",
        borderBottomLeftRadius: "85px",
      }}
    >
      <div style={{ width: "80%", margin: "auto" }}>
        <h2 className="resource-section-title">Overview</h2>
        <p style={{ lineHeight: "28px" }}>
          Dubai ranks among the world's top five best cities to work, where career
          prospects go hand-in-hand with first-class infrastructure and an unbeatable
          quality of life. Millions of professionals from over 200 countries have
          chosen to move here in the recent past, drawn by the city's entrepreneurial
          spirit, competitive salaries, mandatory health insurance, and generous
          gratuity packages.
        </p>
      </div>
    </div>
  
    <div className="resource-container">
      <h2 className="resource-section-title">Key Topics</h2>
      <div className="resource-card-container">
        <div className="resource-card">
          <h4>Finding a Job</h4>
          <p>
            Discover online and offline job-hunting resources, trusted websites, and
            recruitment agencies to help you land the right job in Dubai.
          </p>
        </div>
        <div className="resource-card">
          <h4>Getting a Visa</h4>
          <p>
            Learn about different types of employment visas, how to apply, and the
            sponsorship process for you and your family.
          </p>
        </div>
        <div className="resource-card">
          <h4>Labour Laws</h4>
          <p>
            Understand Dubai's labour laws and regulations that ensure fair treatment
            for all employees.
          </p>
        </div>
        <div className="resource-card">
          <h4>Employee Benefits</h4>
          <p>
            Find out about employee benefits, including health insurance, allowances,
            relocation assistance, and leave entitlements.
          </p>
        </div>
        <div className="resource-card">
          <h4>Skills Development</h4>
          <p>
            Explore the various skills development and career growth opportunities
            available for professionals working in Dubai.
          </p>
        </div>
        <div className="resource-card">
          <h4>Work-Life Balance</h4>
          <p>
            Learn how Dubai’s work-life balance allows professionals to enjoy both
            their careers and the city’s recreational offerings.
          </p>
        </div>
      </div>
    </div>
  
    <div
      style={{
        width: "90%",
        margin: "40px auto",
        padding: "50px",
        borderTopLeftRadius: "70px",
      }}
    >
      <div
        style={{
          width: "90%",
          lineHeight: "28px",
          margin: "30px 50px",
          color: "#505050",
        }}
      >
        <h3>Finding a Job in Dubai</h3>
        <p>
          Prospective employees can use both online and offline resources for job
          hunting. Trusted job search sites include:
        </p>
        <ul>
          <li>Bayt and Laimoon (homegrown initiatives)</li>
          <li>Monster Gulf, Naukri Gulf, and Indeed</li>
          <li>LinkedIn for networking</li>
          <li>Glassdoor for company reviews</li>
        </ul>
        <p>
          Recruitment agencies can also play a pivotal role in guiding talent toward
          suitable job paths aligned with their skills and experience.
        </p>
      </div>
    </div>
  
    <div
      style={{
        width: "90%",
        margin: "40px auto",
        padding: "50px",
        borderTopLeftRadius: "70px",
        background: "#d0e4eb",
      }}
    >
      <div
        style={{
          width: "90%",
          lineHeight: "28px",
          margin: "30px 50px",
          color: "#505050",
        }}
      >
        <h3>Getting a Dubai Employment Visa</h3>
        <p>
          All professionals need a residence permit and work visa to live and work
          in Dubai. Employers typically handle the visa and permit process, but job-seeker visas are also available for those looking for employment opportunities while in the country.
        </p>
        <p>
          Entrepreneurs starting their own companies can also self-sponsor. Visa
          categories vary for skilled workers, investors, and specialized
          professionals, with visa holders being able to sponsor family and
          dependents in some cases.
        </p>
      </div>
    </div>
  
    <div
      style={{
        width: "90%",
        margin: "40px auto",
        padding: "50px",
        borderTopLeftRadius: "70px",
      }}
    >
      <div
        style={{
          width: "90%",
          lineHeight: "28px",
          margin: "30px 50px",
          color: "#505050",
        }}
      >
        <h3>Understanding Dubai's Labour Laws</h3>
        <p>
          Dubai's labour laws ensure equal treatment for all employees and prevent
          discrimination based on race, colour, religion, sex, or disability. These
          laws cover everything from work contracts to hours, ensuring fair practices
          across industries.
        </p>
        <p>
          Equal pay for men and women is enforced, and employers are not allowed to
          alter employment contracts without mutual consent. More information is
          available on the Ministry of Human Resources and Emiratisation (MOHRE)
          website.
        </p>
      </div>
    </div>
  
    <div
      style={{
        width: "90%",
        margin: "40px auto",
        padding: "50px",
        borderTopLeftRadius: "70px",
        background: "#f2f2f2",
      }}
    >
      <div
        style={{
          width: "90%",
          lineHeight: "28px",
          margin: "30px 50px",
          color: "#505050",
        }}
      >
        <h3>Employee Benefits in Dubai</h3>
        <p>
          Health insurance is mandatory for all employees, and many companies offer
          transportation, housing allowances, and relocation assistance. Leave
          entitlements include annual leave, sick days, and maternity leave.
        </p>
        <p>
          Companies often cover the cost of plane tickets for expatriates to visit
          their home countries once a year.
        </p>
      </div>
    </div>
  
    <div
      style={{
        width: "90%",
        margin: "40px auto",
        padding: "50px",
        borderTopLeftRadius: "70px",
      }}
    >
      <div
        style={{
          width: "90%",
          lineHeight: "28px",
          margin: "30px 50px",
          color: "#505050",
        }}
      >
        <h3>Skills Development and Work-Life Balance</h3>
        <p>
          Dubai offers a range of opportunities for professional growth, including
          courses, seminars, and webinars. Employers often provide training
          programmes to help employees enhance their skills and grow within their
          roles.
        </p>
        <p>
          Dubai's vibrant lifestyle allows professionals to unwind, with gyms, spas,
          outdoor trips, and fine dining options to maintain a healthy work-life
          balance.
        </p>
      </div>
    </div>
  </>
  
  )
}
