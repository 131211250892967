import React from "react";
import { FaCaretDown } from "react-icons/fa";
import { IoHomeOutline } from "react-icons/io5";
import { MdOutlineMenu } from "react-icons/md";
import { Link } from "react-router-dom";
import accommodation from "../assets/images/accommodation.png";
import bdIcon from "../assets/images/bangladesh.png";
import factoryIcon from "../assets/images/factory.png";
import logoImg from "../assets/images/logo-120x120.png";
import teamIcon from "../assets/images/team.png";
import SearchForm from "./SearchForm";

export default function ResourceNavbar() {
  return (
    <>
      <header>
        <Link to="/" className="logo">
          <img src={logoImg} width="70px" height="70px" alt="logo" />
        </Link>
        <search className="searchform">
          <SearchForm />
        </search>
        <input type="checkbox" id="menu-bar" />
        <label htmlFor="menu-bar">
          <MdOutlineMenu style={{ fontSize: "45px" }} />
        </label>
        <nav className="navbar-1">
          <ul>
            <li style={{ display: "flex", alignItems: "center" }}>
              <IoHomeOutline style={{ color: "crimson" }} />
              <Link to="/" className="nav-link-1">
                Home
              </Link>
            </li>
            <li>
              <Link style={{display:"flex",alignItems:"center",gap:"3px"}} to="#" className="nav-link-1">
                <img src={bdIcon} width={17} alt="worldicon" />
                Why Bangladesh
                <FaCaretDown />
              </Link>
              <ul>
                <li>
                  <Link className="element" to="/resource/why-investment-in-bangladesh">
                    Business Setup in Bangladesh
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/family-office">
                    Family Office
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/headquarters">
                    Headquarters
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/manufactures">
                    Manufactures
                  </Link>
                </li>
                <li>
                  <Link
                    className="element"
                    to="/resource/multinational-corporations"
                  >
                    Multinational Corporations
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/vc-pc-companies">
                    VC and PE Companies
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/d33-agenda">
                    D33 Agenda
                  </Link>
                </li>
                <li>
                  <Link className="element" to="#">
                    Start a Business <FaCaretDown />
                  </Link>
                  <ul className="audit-menu">
                    <li>
                      <Link className="element" to="/resource/proprietorship">
                        Proprietorship
                      </Link>
                    </li>

                    <li>
                      <Link className="element" to="/resource/partnership">
                        Partnership
                      </Link>
                    </li>
                    <li>
                      <Link className="element" to="/resource/cr-bd">
                        Company Registration in Bangladesh
                      </Link>
                    </li>
                    <li>
                      <Link className="element" to="/resource/branch-office-bd">
                        Liaison or branch office in Bangladesh{" "}
                      </Link>
                    </li>
                    <li>
                      <Link className="element" to="/resource/business-licenses-bangladesh">
                        License and Approval{" "}
                      </Link>
                    </li>
                    <li>
                      <Link className="element" to="/resource/legal-system">
                      Legal System
                      </Link>
                    </li>
                    <li>
                      <Link className="element" to="/resource/foreign-invest">
                      Foreign Investment
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <Link style={{display:"flex",alignItems:"center",gap:"3px"}} to="#" className="nav-link-1">
                <img src={factoryIcon} width={17} alt="startupiconImg" />
                Industries <FaCaretDown />
              </Link>
              <ul>
              <li>
                  <Link className="element" to="/resource/technology-and-ict">
                    Technology and ICT
                  </Link>
                </li>
              <li>
                  <Link className="element" to="/resource/rmg-industries">
                    RMG Industries
                  </Link>
                </li>
                <li>
                  <Link
                    className="element"
                    to="/resource/construction-and-real-estate"
                  >
                    Construction and real estate
                  </Link>
                </li>
                <li>
                  <Link
                    className="element"
                    to="/resource/healthcare-and-pharmaceuticals"
                  >
                    Healthcare and pharmaceuticals
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/food-and-agriculture">
                    Food and agriculture
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/energy-and-cleantech">
                    Energy and cleantech
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/finance-and-wealth">
                    Finance and wealth
                  </Link>
                </li>                                                     
              
                <li>
                  <Link className="element" to="/resource/retail-and-ecommerce">
                    Retail and e-commerce
                  </Link>
                </li>
            
                <li>
                  <Link className="element" to="/resource/trade-and-logistics">
                    Trade and logistics
                  </Link>
                </li>
              
                <li>
                  <Link
                    className="element"
                    to="/resource/design-media-and-entertainment"
                  >
                    Design, media and entertainment
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link style={{display:"flex",alignItems:"center",gap:"3px"}} to="#" className="nav-link-1">
              <img src={teamIcon} width={17} alt="worldicon" />
                Setting up a Business <FaCaretDown />
              </Link>
              <ul>
                <li>
                  <Link
                    className="element"
                    to="/resource/company-set-up-options"
                  >
                    Company set-up options
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/mainland-companies">
                    Mainland companies
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/free-zone-companies">
                    Free zone companies
                  </Link>
                </li>
                <li>
                  <Link
                    className="element"
                    to="/resource/employees-and-administration"
                  >
                    Employees and administration
                  </Link>
                </li>
                <li>
                  <Link
                    className="element"
                    to="/resource/regulations-laws-and-tax"
                  >
                    Regulations, laws and tax
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/support-for-smes">
                    Support for SMEs
                  </Link>
                </li>
                <li>
                  <Link
                    className="element"
                    to="/resource/support-for-manufacturers-and-exporters"
                  >
                    Support for manufacturers and exporters
                  </Link>
                </li>
                <li>
                  <Link
                    className="element"
                    to="/resource/dubai-unified-licence"
                  >
                    Dubai Unified Licence
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" className="nav-link-1">
              <img src={accommodation} width={17} alt="worldicon" />
                Live and Work
                <FaCaretDown />
              </Link>
              <ul>
                <li>
                  <Link className="element" to="/resource/visas-and-entry">
                    Visas and entry
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/get-a-job">
                    Get a job
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/open-a-bank-account">
                    Open a bank account
                  </Link>
                </li>
                {/* <li>
                  <Link className="element" to="/resource/dubai-neighbourhoods">
                    Dubai neighbourhoods
                  </Link>
                </li> */}
                <li>
                  <Link
                    className="element"
                    to="/resource/introduction-to-real-estate"
                  >
                    Introduction to real estate
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/roads-and-driving">
                    Roads and driving
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/public-transport">
                    Public transport
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/education-ecosystem">
                    Education ecosystem
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/health-and-wellness">
                    Health and wellness
                  </Link>
                </li>
                <li>
                  <Link
                    className="element"
                    to="/resource/resources-for-city-living"
                  >
                    Resources for city living
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/work-remotely">
                    Work remotely
                  </Link>
                </li>
                <li>
                  <Link
                    className="element"
                    to="/resource/practical-information"
                  >
                    Practical information
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/resource/accommodation">
                    Accommodation
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li>
              <Link to="#" className="nav-link-1">
                <img src={categoryicon} width={17} alt="categoryicon" /> Others{" "}
                <FaCaretDown />
              </Link>
              <ul className="left-dropdown">
                <li>
                  <Link className="element" to="/civil-litigation">
                    Civil Litigation
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/criminal-litigation">
                    Criminal Litigation
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/fire-license">
                    Fire License
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/factory-license">
                    Factory License
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/customs-bond-license">
                    Custom bond License
                  </Link>
                </li>
                <li>
                  <Link
                    className="element"
                    to="/environment-clearance-certificate"
                  >
                    Environment Clearance Certificate
                  </Link>
                </li>
                <li>
                  <Link className="element" to="/company-dissolution">
                    Company Winding up
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link className="nav-link-1" to="/resource">
                {" "}
                <FcBusinessman /> Resource{" "}
              </Link>
            </li> */}
          </ul>
        </nav>
      </header>
    </>
  );
}
