import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import Faq from '../../components/Faq'
import Meta from '../../components/Meta'
import BSTIRegistrationImg from '../../assets/images/BSTI-registration-process.png'
import RequiredBSTIPermissionImg from '../../assets/images/Required-Documents-for-BSTI-Permission.png'
import BSTIPermissionImg from '../../assets/images/BSTI-permission.png'
import BSTIPermission_og_img from '../../assets/images/BSTI-permission.png'
import Marquee from 'react-fast-marquee';
import { startupRelatedArticales } from '../../data/relatedArticale'

function BstiPermission() {

    const faqItems = [
        {
            title: 'How do I get BSTI approval?',
            content: <strong>Process Steps:</strong>
        },
        {
            title: 'What are the functions of BSTI?',
            content: <strong>The primary activities of the Bangladesh Standards and Testing Institution (BSTI)
                are: standardization of services and products (S);</strong>
        },
        {
            title: 'What is full form ISO?',
            content: <strong>It is an international standard-setting body composed of representatives from
                various national standards organizations.</strong>
        }
    ];

    return (
        <>
            <Meta
                title="BSTI Permission in Bangladesh | Products BSTI"
                description="BSTI Registration for services and products trade in local and international market. The Bangladesh Standards and Testing Institution (BSTI) was established by the Government"
                image={BSTIPermission_og_img}
                keywords="products bsti, bsti approval, bsti registration"
            />

                   

            <div className="container-fluid hero-header bg-light py-5 ">
                <div className="container py-5">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h1 className="display-4 mb-3 animated slideInDown">BSTI Permission</h1>
                            <nav aria-label="breadcrumb animated slideInDown">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="#">Startup</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">BSTI Permission</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 animated fadeIn">
                            <img className="img-fluid animated pulse infinite" style={{ animationDuration: '3s' }} src={BSTIPermissionImg} alt='BSTI-permission' />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <p className="color-text fs-5 mb-4">
                        <b>PROCEDURE FOR BSTI CERTIFICATION</b>
                    </p>
                    <p>
                        BSTI Registration, The Bangladesh Standards and Testing Institution (BSTI) was established
                        by the Government through an Ordinance passed in July 1985. BSTI is headed by a Director
                        General (Additional Secretary to the Government).<br /><br />

                        The primary activities of BSTI Registration The Bangladesh Standards and Testing Institution
                        (BSTI) are standardization of services and products (S); introduction of the international
                        unit system of weights and measures and promotion of metrology services (M); promotion of
                        quality assurance activities; rendering testing facilities for services and products;
                        preparation, promotion and adoption of national standards. The Institution is also empowered
                        with some regulatory measures in these fields. The Institutional budget is fully supported by
                        its own income, i.e. it is functioning as a self-financing organization.<br /><br />
                        The Institution has also taken steps to create awareness among different bodies and
                        entrepreneurs for the adoption of all key ISO standards (ISO 9000, 14000, etc.), CAC, IEC.
                        The Bangladesh Standards and Testing Institution (BSTI) signed the International
                        Electrotechnical Commission's (IEC) affiliate country program pledge in 2001.<br /><br />

                        The importance of BSTI Registration is being increasingly felt due to the government's
                        cardinal policy to make industries export-oriented and to supply the consumers at home and
                        abroad with quality products. The Institution is emerging as a forceful exponent in the
                        overall development of the country. The introduction of the international unit system
                        (SI Units) and promotion of metrology services are also gaining momentum.
                    </p>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6  "  >
                            <img className="img-fluid" src={BSTIRegistrationImg} alt='BSTI-registration-process' />
                        </div>
                        <div className="col-lg-6  "  >
                            <div className="h-100">
                                <p className="color-text fs-5 mb-4">BSTI Registration</p>
                                <p>
                                    <span>
                                        <strong>8 (eight) steps for obtaining Certification of Marks (CM) license.</strong>
                                    </span>
                                    <br />

                                    <span>
                                        1. Any Company/producer intends to use, Standard mark of BSTI Registration
                                        in relation to any article or process shall apply to the Institution for
                                        obtaining CM license in a prescribed form.
                                    </span><br />
                                    <span>
                                        2. After receiving the application along with necessary and relevant papers
                                        an inspection is being arranged and the inspection report is prepared.
                                    </span><br />
                                    <span>
                                        3. If the inspection report is found satisfactory, then samples are collected
                                        on a random basis and those are jointly signed and sealed by the BSTI Officer
                                        and the producer.
                                    </span><br />
                                    <span>
                                        4. The collected sample is tested in the BSTI Laboratory or any other
                                        laboratory approved by BSTI according to the relevant Bangladesh Standard
                                        (BDS)
                                    </span><br />
                                    <span>
                                        5. If the results of the test parameters are found in conformity with the
                                        relevant BDS. a license is granted in favor of that article.
                                    </span><br />
                                    <span>
                                        6. Normally a license is granted for a period of three years, which is being
                                        renewed if the samples are found satisfactory after following the same
                                        procedure as has been described in sl.no. (b) (e), for a subsequent period
                                        of another three years.
                                    </span><br />
                                    <span>
                                        7. A license is to pay fees in favor of BSTI which is known as CM fee The
                                        breakup of the CM fees is as follows: <br />
                                        <span>
                                            <strong className='px-4'>
                                                i. Application fee: Tk. 1000 for new and Tk. 500 for renewal
                                            </strong>
                                        </span><br />
                                        <span>
                                            <strong className='px-4'>
                                                ii. License fee: Tk. 200/= per year.
                                            </strong>
                                        </span><br />
                                        <span>
                                            <strong className='px-4'>
                                                iii. Marking Fee: Tk. 0.07% of Ex. factory price for fruit items and Tk.
                                                0.10% of Ex. factory price for other items. For fruit items Min. fee Tk.
                                                1250/= to Max. Tk. 10,00,000/= and for other items Min. fee Tk. 1875/=
                                                to 15,00,000/=
                                            </strong>
                                        </span>
                                    </span><br />
                                    <span>
                                        8. A license granted in favor of any article can be canceled, withdrawn, or
                                        revoked at any time if it is found that the license has violated any of the
                                        conditions specified in the license and BSTI Ordinance as well.
                                    </span>
                                </p>

                                <p>
                                    A schematic diagram, explaining the procedure for grant of a BSTI CM license is given in.<br></br><br></br>
                                    Although the above-mentioned procedure is quite simple no system works
                                    properly in this country. People often complain that getting a BSTI
                                    Registration without a reference from someone powerful is like getting moon
                                    in hand. Often people have to pay extra lubrication money to get a BSTI
                                    certificate. Even sometimes start-up entrepreneurs become compelled to
                                    postpone their long-desired business initiative due to regulatory burden and
                                    unnecessary hassles from various government organizations like BSTI. BSTI
                                    Registration.

                                </p>
                                <p className="color-text fs-5 mb-4">
                                    Preliminary inspection
                                </p>
                                <p>
                                    Preliminary inspection is carried out on a day, mutually agreed upon, to
                                    examine the availability of the facilities for producing a standard product
                                    and to draw samples for independent testing. The details of the preliminary
                                    inspection are recorded in a suitable proforma known as the preliminary
                                    inspection report. On the basis of this report. The decision was taken
                                    regarding the grant of license. The information, which is to collect at the
                                    time for BSTI Registration of preliminary inspection, are:
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-0">
                <div className="container">
                    <p>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                General information.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                About raw materials.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Manufacturing process.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Packing and marking.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Laboratory and inspection procedure.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Hygienic and environmental conditions at factory premises in case of a food processing plants.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Testing facilities available as per Bangladesh Standard.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Storage facilities.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Testing personnel etc.
                            </span>
                        </div>
                        <div className="d-flex align-items-center mb-2">
                            <i className="fa fa-check bg-light color-text btn-sm-square rounded-circle me-3 fw-bold" />
                            <span>
                                Collection of samples.
                            </span>
                        </div>
                        Two sets of samples are collected on a random basis, which is sealed with the joint signature
                        of the inspection officer and the representative of the applicant. Both the samples are kept
                        in the custody of the applicant who is advised to submit one set of the samples to the BSTI
                        lab. And the other to keep with him as a reference sample.<br /><br />
                        <p className="color-text fs-5 mb-4">
                            <strong>
                                Testing:
                            </strong>
                        </p>
                        <p>
                            The sample collected by the inspecting officer during the preliminary inspection is
                            submitted to any of the BSTI Laboratories situated at Dhaka. Chittagong, Rajshahi and
                            Khulna whichever seems to be convenient. The testing fee is to be borne by the applicant.
                            In the case of the laboratories other than BSTI, the applicant is advised to pay the testing
                            fee directly to the concerned laboratory.
                        </p>
                        <p className="color-text fs-5 mb-4">
                            <strong>
                                Grant / Renewal of license:
                            </strong>
                        </p>
                        <p>
                            After the completion of the above-mentioned formalities, if it is seen that the preliminary
                            inspection report is satisfactory and the test parameters are found in conformity with the
                            relevant Bangladesh standard, a decision is taken for the grant of a BSTI Registration in
                            favor of that product. After the due payment of license and marking fee a license is
                            issued.<br /><br />
                            The licensee is to apply to the institution for the renewal of their license 3 months
                            before the expiry of the licensing period. The license is renewed, at interval 3 years,
                            only if the test result of the sample duly collected from the factory is found inconsistent
                            with the BDS.
                        </p>
                        <p>
                            <img className="mx-auto responsive-img" src={RequiredBSTIPermissionImg}
                                alt='Required-Documents-for-BSTI-Permission' style={{ width: 1400, height: 300 }}
                            />
                        </p>
                        <Table striped bordered hover variant="light blue" >
                            <thead>
                                <tr>
                                    <th>Procedure Name</th>
                                    <th colSpan={2}>BSTI Registration for Imported Products</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td colSpan={3}><strong>BSTI Certificate for Imported Products</strong></td>

                                </tr>
                                <tr>
                                    <td rowSpan={18}><strong>Description</strong></td>
                                    <td>Category</td>
                                    <td>Clearance Certificate</td>
                                </tr>
                                <tr>
                                    <td>Issuing Agency</td>
                                    <td>Bangladesh Standards & Testing Institution</td>
                                </tr>
                                <tr>
                                    <td>Incumbent Office</td>
                                    <td>Bangladesh Standards & Testing Institution<br />
                                        Address: Maan Bhaban, 116/A, Tejgaon Industrial Area,Dhaka-1208<br />
                                        Fax : (880-2)-9131581<br />
                                        E-mail : bsti@bangla.net<br />
                                        Web : www.bsti.gov.bd<br />
                                        Phone : 8870275, 8870277, 8870278
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}><strong>Issuance of BSTI Standard Certificate</strong></td>

                                </tr>
                                <tr>
                                    <td><strong>Serial</strong></td>
                                    <td><strong>Required documents</strong></td>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Application in the Importers Pad</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Packing List</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Import Registration Certificate (IRC)</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Letter of Credit (L/C)</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Bill of Lading (B/L)</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Invoice</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Valid Trade License</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Valid TIN Certificate</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Documents for Country of Origin of the product</td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>Health Certificate & Radiation Certificate for Food Items)</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <strong>
                                            Test Fees: product wise<br />
                                            Marking Fees: 0.1 % of import value (CIF value)Process Map
                                        </strong>
                                    </td>

                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        Bangladesh Standard and Testing Institute (BSTI)
                                    </td>

                                </tr>
                            </tbody>
                        </Table>
                    </p>
                </div>
            </div>

            <div className='container-xxl center-background1'>
                <h2> Related Articles</h2>
            </div>

            <div className='container-xxl center-background1'>
                <Marquee className="marquee-container">
                   {
                    startupRelatedArticales
                   }
                </Marquee>
            </div>

            <div className="container py-5 my-0">
                <h2 className="color-text text-center mb-4">BSTI Permission FAQ</h2>
                <Faq items={faqItems} />
            </div>
                   
        </>
    )
}

export default BstiPermission