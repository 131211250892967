import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
export default function TradeAndLogistics() {
  return (
    <>
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Trade and logistics
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <h2 className="resource-section-title">Epicentre of global trade</h2>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Building on its history as a trade hub, Dubai is ideally situated at
            the intersection of established and emerging commercial routes,
            bridging East and West. This strategic nerve centre enables the
            seamless flow of goods, business and investments across the Middle
            East, Europe, Africa and Asia.
            <br />
            <br />
            Dubai’s well-established logistics and supply chain ecosystem,
            encompassing some of the world’s best ports, airports and a
            hyper-modern roads and transportation network, makes unparalleled
            connectivity possible. Dubai’s world-class infrastructure
            facilitates efficiency and reliability in trade operations, making
            it the preferred destination for businesses seeking to diversify and
            expand into new, high-potential geographies.
          </p>
          <h3>D33: Building future trade horizons</h3>
          <p>
            Retail and wholesale trade account for the majority of Dubai’s
            annual GDP. Trade (wholesale and retail) is the most significant
            contributor to Dubai’s GDP yearly. The sector will also be
            instrumental in achieving the key goals outlined in the D33 economic
            agenda. These include doubling the foreign trade volume and
            positioning Dubai as one of the world’s top five logistics hubs
            through connectivity, services and operations.
            <br />
            <br />
            Key pillars include expanding Dubai’s trade horizons by establishing
            new economic corridors and signing trade agreements. The strategy
            aims to transform global trade management by implementing
            integrated, purpose-built, multimodal supply chain solutions
            spanning sea, air and land routes. There’s also a focus on boosting
            government expenditure and investments in infrastructure to create a
            robust, efficient logistical ecosystem.
          </p>
        </div>
      </div>
      <div className="resource-container">
        <h2 className="resource-section-title">
          Dubai - your global trade partner
        </h2>
        <div className="resource-card-container">
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F91D;</span>
            </div>
            <h4>Trade partnerships</h4>
            <p>Key partners including China, India, USA and KSA.</p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F3E0;</span>
            </div>
            <h4>Smart networks</h4>
            <p>World-class support for transport and logistics.</p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F4BB;</span>
            </div>
            <h4>An ideal gateway</h4>
            <p>Access existing and emerging markets across MEASA.</p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span class="icon">&#128104;&#8205;&#127891;</span>
            </div>
            <h4>Competitive landscape</h4>
            <p>
              Business, trade, and investment landscape to global economies.
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "10px",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "20px 50px",
            color: "#505050",
          }}
        >
          <h3>Trailblazer in logistics</h3>
          <p>
            Exporting from Dubai affords access to billions of consumers
            spanning the broader MEASA region. This is enabled by the city’s
            highly efficient logistics ecosystem, which contributes roughly 8%
            to the UAE’s overall GDP. Dubai’s logistics prowess has also aided
            the UAE to be recognised as one of the world’s top 12 countries on
            the World Bank Logistics Performance Index.
            <br />
            <br />
            Thanks to substantial infrastructural investments, Dubai’s logistics
            sector has become a significant trade and re-export base for
            commodities on the Europe-Asia route. The city’s world-renowned
            maritime and logistics capabilities, robust legal and legislative
            framework, and conducive business environment have consistently
            ranked it as one of the top 5 shipping hubs on the International
            Shipping Centre Development Index.
          </p>
          <h3 style={{ marginTop: "40px" }}>Diverse transport solutions</h3>
          <div className="row" style={{ margin: "20px 0" }}>
            <div className="col-lg-6">
              <h5>Airlines</h5>
              <p>
                Dubai is the base of Emirates Airline, the UAE’s largest and the
                world’s fourth-largest by passenger kilometres flown. Its
                network spans 144 cities in 76 countries, operating over 70
                weekly freighter departures from Dubai World Central.
              </p>
            </div>
            <div className="col-lg-6">
              <h5>Cargo logistics</h5>
              <p>
                Dubai is served by Dubai Ports World (DP World), a global leader
                in innovative logistics and supply chain solutions. It
                specialises in cargo logistics, maritime services, port terminal
                operations, and free zones.
              </p>
            </div>
            <div className="col-lg-6" style={{ marginTop: "25px" }}>
              <h5>Seaports</h5>
              <p>
                Sea trade is bolstered by Dubai’s three major ports, Jebel Ali,
                named the best in the region for more than 20 consecutive years,
                as well as Port Rashid and Al Hamriya. All three of them play
                pivotal roles in boosting the city’s business and trade
                capabilities.
              </p>
            </div>
            <div className="col-lg-6" style={{ marginTop: "25px" }}>
              <h5>Railroads</h5>
              <p>
                The UAE recently announced Etihad Rail, a 1,200km megaproject
                that will be a part of the US$100 billion (AED367 billion) Gulf
                Cooperation Council (GCC) rail programme. When ready, Etihad
                Rail is expected to serve 16 million passengers and transport 50
                million tonnes of freight.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>At the heart of historic and future trade pathways</h3>
          <p>
            Dubai has historically been the heart of key trade corridors with
            Turkey, India, Bangladesh and Africa. A robust India-UAE start-up
            corridor was established in 2022, further strengthening the business
            connection between the two countries.
            <br />
            <br />
            These corridors comprise a significant part of the global primary
            and secondary asset market. Future plans involve the establishment
            of more than 400 new trade corridors with cities across Africa,
            Latin America and Southeast Asia, elevating both regional economic
            activity and Dubai’s business influence and strength.
          </p>

          <h5 style={{ marginTop: "30px" }}>Dubai Logistics Corridor</h5>
          <p>
            The upcoming Dubai Logistics Corridor will be a major milestone,
            bridging critical trade zones such as the Jebel Ali Port, the Jebel
            Ali Free Zone Authority (JAFZA) and Al Maktoum International
            Airport. This progressive project is set to maximise the city’s
            logistical operations and promote greater efficiency in the local
            and regional transfer of goods. Implementing this route is expected
            to reduce sea-air transport time to one hour from the current four
            hours and increase Dubai’s logistics efficiency and goods-transport
            capability by 400%.
          </p>
        </div>
      </div>

      <div className="row" style={{ width: "80%", margin: "auto" }}>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h3>US$27 billion</h3>
            <p style={{ marginBottom: "0px" }}>
              estimated value of UAE freight and logistics market by 2029.
            </p>
          </div>
        </div>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h3>US$7 trillion</h3>
            <p style={{ marginBottom: "0px" }}>
              projected value of goods and services foreign trade value over the
              next decade under D33.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h4>Diversified trade footprint</h4>
        <p>
          Dubai is one of the most important regional import and re-export hubs,
          actively developing innovative solutions to boost multiple industries,
          such as automotive, energy and more.
          <br />
          <br />
          Growth in motor vehicle transportation has been spearheaded by JAFZA’s
          strong connectivity and cohesive infrastructure, which attract
          industry giants like Ford, General Motors, Honda, Mitsubishi and
          Volkswagen. DP World’s used car market, The Dubai Auto Zone (DAZ) also
          provides a unique platform that encourages further growth for the auto
          industry.
          <br />
          <br />
          The global energy industry is also turning towards Dubai, encouraged
          by the government’s strong focus on renewables and investments in
          major projects such as the US$13.6 billion (AED50 billion) Sheikh
          Mohammed Bin Rashid Al Maktoum Solar Park, the world’s largest
          single-site solar park. This steadfast focus on enabling the energy
          sector is supported by major homegrown players like Emirates National
          Oil Company (ENOC), as well as global giants like BP, China Petroleum
          Engineering and Construction Company (CPECC), Halliburton and many
          more across upstream, midstream and downstream.
        </p>
        <h4>Enhancing security and efficiency</h4>
        <p>
          Trade in Dubai is streamlined through several initiatives that
          prioritise efficiency and security. A collaboration between Dubai
          Customs and the Dubai Digital Authority yielded the Early Cargo
          Targeting System, designed to detect and combat illegal trafficking
          and commercial activity. Its focus is protecting the integrity and
          safety of the city’s operations and cultivating a secure business
          environment in Dubai.
        </p>
      </div>
    </>
  );
}
