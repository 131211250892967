import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
export default function FreeZoneCompanies() {

  return (
    <>
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Free zone companies
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <h5>
            {" "}
            Learn more about Dubai's free zones and the process of setting up a
            company within them.
          </h5>
          <p style={{ width: "80%", lineHeight: "28px", marginTop: "20px" }}>
            Dubai's free zones offer multiple opportunities for businesses,
            providing dedicated support for specific industries, tax-free
            profits, and 100% foreign ownership.
            <br />
            <br />
            While these free zones specialise in specific industries with
            tailored support depending on the sector, it’s important to note
            that companies set up in the free zone cannot trade within the UAE
            without a specific mainland licence.
            <br />
            <br />
            We take you through the steps involved in setting up a free zone
            company:
          </p>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "auto",
          padding: "50px 50px 0",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "0px 50px",
            color: "#505050",
          }}
        >
          <h3>Choose your free zone</h3>
          <b>
            Dubai has more than 20 free zones, catering to specific industries
            and offering dedicated support.
          </b>
          <p>
            Choosing a free zone comes down to what your business goals are, as
            well as the practical needs of your company. Many of Dubai's free
            zones allow standard trading enterprises across a range of sectors,
            but some are focused on just a few specialist areas – and offer
            dedicated and knowledgable support to companies within the zone.
            <br />
            <br />
            Some of the most popular free zones include Jebel Ali Free Zone
            (JAFZA), Dubai International Financial Centre (DIFC), Dubai Internet
            City and DMCC. It's worth spending time getting to know the city's
            free zones before deciding which one to choose for your company.
          </p>
        </div>
        <div className="container my-5">
          <h2 className="mb-4">Dubai's free zones</h2>
          <p className="mb-5">
            Browse information on Dubai's business landscape and how to start or
            move your business to Dubai.
          </p>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
              <div className="card h-100">
                <img
                  src="https://www.investindubai.gov.ae/-/media/Images/business-in-dubai/free-zone-images/dsc.jpg?cx=0.5&cy=0.5&cw=440&ch=440"
                  className="card-img-top"
                  alt="Mainland companies"
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{ background: "none", boxShadow: "none" }}
                  >
                    Dubai Studio City (DSC)
                  </h5>
                  <div
                    className="card-text"
                    style={{ background: "none", boxShadow: "none" }}
                  >
                    <h5>Maket specialisation:</h5>
                    <p>
                      Broadcasting, Arts & entertainment, Media, TV & film
                      production
                    </p>
                    <h5>About</h5>
                    <b>Industries/sectors:</b>
                    <ul>
                      <li>Broadcasting</li>
                      <li>Media and new media</li>
                      <li>Music</li>
                      <li>TV and film production</li>
                    </ul>
                    <b>Permitted licences:</b>
                    <ul>
                      <li>Commercial Licence</li>
                    </ul>
                    <b>Permitted company types:</b>
                    <ul>
                      <li>
                        Branch of a Foreign Company (parent company should be in
                        existence for a minimum of 2 years)
                      </li>
                      <li>
                        Branch of a UAE Company including entities established
                        in other UAE Free Zones
                      </li>
                      <li>FZ LLC</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
              <div className="card h-100">
                <img
                  src="https://www.investindubai.gov.ae/-/media/Images/business-in-dubai/free-zone-images/dso.jpg?cx=0.5&cy=0.5&cw=440&ch=440"
                  className="card-img-top"
                  alt="Mainland companies"
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{ background: "none", boxShadow: "none" }}
                  >
                    Dubai Silicon Oasis (DSO)
                  </h5>
                  <div
                    className="card-text"
                    style={{ background: "none", boxShadow: "none" }}
                  >
                    <h5>Maket specialisation:</h5>
                    <p>
                      Construction, Education, Energy, Industrial, Tech and ICT,
                      Real estate, Trade
                    </p>
                    <h5>About</h5>
                    <b>Industries/sectors:</b>
                    <ul>
                      <li>Construction</li>
                      <li>Education</li>
                      <li>Energy</li>
                      <li>Industrial</li>
                      <li>Information Technology</li>
                      <li>Real Estate</li>
                      <li>Trade</li>
                    </ul>
                    <b>Permitted licences:</b>
                    <ul>
                      <li>Service Licence</li>
                      <li>Trading Licence</li>
                      <li>Industrial Licence</li>
                      <li>Special Licence</li>
                    </ul>
                    <b>Permitted company types:</b>
                    <ul>
                      <li>Branch of a Foreign Company</li>
                      <li>Branch of a UAE Company</li>
                      <li>Free Zone Establishment (FZE)</li>
                      <li>Free Zone Company (FZCO)</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
              <div className="card h-100">
                <img
                  src="https://www.investindubai.gov.ae/-/media/Images/business-in-dubai/free-zone-images/dcc.jpg?cx=0.26&cy=0.46&cw=440&ch=440"
                  className="card-img-top"
                  alt="Dubai CommerCity"
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{ background: "none", boxShadow: "none" }}
                  >
                    Dubai CommerCity (DCC)
                  </h5>
                  <div
                    className="card-text"
                    style={{ background: "none", boxShadow: "none" }}
                  >
                    <h5>Market specialisation:</h5>
                    <p>
                      Construction, Food & beverages, Education, Financial
                      services, Manufacturing, Mining & quarrying, Insurance
                    </p>
                    <h5>About</h5>
                    <b>Industries/sectors:</b>
                    <ul>
                      <li>Mining & Quarrying</li>
                      <li>Manufacturing</li>
                      <li>Construction</li>
                      <li>Food and beverages Trading</li>
                      <li>Education</li>
                      <li>Financial and insurance activities</li>
                    </ul>
                    <b>Permitted licences:</b>
                    <ul>
                      <li>Trade Licence</li>
                      <li>Service Licence</li>
                      <li>Industrial Licence</li>
                      <li>eCommerce Licence</li>
                      <li>General Trading Licence</li>
                      <li>Dual Licence with DET</li>
                    </ul>
                    <b>Permitted company types:</b>
                    <ul>
                      <li>Branch of a Foreign Company</li>
                      <li>Branch of a UAE company</li>
                      <li>Free Zone Company (FZCO)</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
              <div className="card h-100">
                <img
                  src="https://www.investindubai.gov.ae/-/media/Images/business-in-dubai/free-zone-images/dmcc.jpg?cx=0.5&cy=0.5&cw=440&ch=440"
                  className="card-img-top"
                  alt="Dubai Multi Commodities Centre"
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    style={{ background: "none", boxShadow: "none" }}
                  >
                    Dubai Multi Commodities Centre (DMCC)
                  </h5>
                  <div
                    className="card-text"
                    style={{ background: "none", boxShadow: "none" }}
                  >
                    <h5>Market specialisation:</h5>
                    <p>
                      Aviation, Commodities, Construction, E-commerce,
                      Education, Energy, Financial services, Agriculture, Food &
                      beverages, Logistics, Healthcare, Hospitality, Media, Tech
                      and ICT, Trade
                    </p>
                    <h5>About</h5>
                    <b>Industries/sectors:</b>
                    <ul>
                      <li>Aviation</li>
                      <li>Commodities</li>
                      <li>Construction</li>
                      <li>E-commerce</li>
                      <li>Education</li>
                      <li>Energy</li>
                      <li>Financial services</li>
                      <li>Food and agriculture</li>
                      <li>Freight and logistics</li>
                      <li>Healthcare</li>
                      <li>Hospitality</li>
                      <li>Information Technology</li>
                      <li>Media and new media</li>
                      <li>Trade</li>
                    </ul>
                    <b>Permitted licences:</b>
                    <ul>
                      <li>Trading Licence</li>
                      <li>Industrial Licence</li>
                      <li>Service Licence</li>
                      <li>Dual Licence with DET</li>
                    </ul>
                    <b>Permitted company types:</b>
                    <ul>
                      <li>Branch of a Foreign Company</li>
                      <li>Branch of a UAE Company</li>
                      <li>DMCC Company</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          lineHeight: "28px",
          margin: "0px 50px",
          color: "#505050",
        }}
      >
        <h3>Choose your free zone company structure</h3>
        <b>
          Free zones in Dubai offer several legal forms to choose from, with
          specific options within each zone.
        </b>
        <div>
          <p>The most common free zone legal forms include:</p>
          <p>
            <b>Free Zone Establishment (FZE):</b>
            <br />
            <br />A legal entity incorporated/registered by a single
            shareholder, who can either be an individual or a corporate entity.
          </p>
          <b>Free Zone Company (FZCO):</b>
          <div>
            <p>
              A legal entity incorporated/registered with a minimum of two
              shareholders, who can be either individuals or corporate entities.
            </p>
          </div>

          <b>Public Limited Liability Company (PJSC):</b>
          <div>
            <p>
              A corporate entity with multiple shareholders that can offer its
              shares to the public for trading on the stock exchange.
            </p>
          </div>

          <b>Private Limited Liability Company (LLC):</b>
          <div>
            <p>
              A corporate entity with limited liability, formed by a minimum of
              two and a maximum of 50 shareholders, not open to public
              subscription or trading of shares.
            </p>
          </div>

          <b>Branch of a Local or International Company:</b>
          <div>
            <p>
              An extension of an existing local or international company,
              allowing it to conduct business in Dubai while remaining legally
              dependent on its parent company.
            </p>
          </div>

          <div>
            <p>
              For detailed information on company structure in a particular free
              zone, please see that zone's website, or contact their support
              team.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          lineHeight: "28px",
          margin: "50px 50px",
          color: "#505050",

        }}
      >
        <h3>Start the free zone company set-up process</h3>
        <b>
          Setting up a freezone company can mostly be done online, thanks to
          streamlined processes.
        </b>
        <div>
          <p>
            Free zones make the process of setting up a company as smooth as
            possible, but make sure you have everything you'll need to complete
            the process – including all relevant paperwork, business plans, and
            anything else the free zone will need.
            <br />
            <br />
            Every free zone is different, so check your chosen zone's specific
            requirements and timelines before starting.
          </p>
          <ul>
            <p>Typical steps include:</p>
            <li>
              Registering the company, for which the owner needs to provide a
              copy of their passport, plus the original company documents if
              setting up a branch office in Dubai.
            </li>
            <li>Leasing business premises.</li>
            <li>
              Obtaining a business licence, which takes, on average, 3-5 working
              days.
            </li>
            <li>
              Setting up additional business services, such as setting up a bank
              account and arranging visas for foreign employees.
            </li>
          </ul>
        </div>
      </div>
      
      <div className="container my-5">
        <h2 className="mb-4">More from Dubai for business</h2>
        <p className="mb-5">
          Browse information on Dubai's business landscape and how to start or
          move your business to Dubai.
        </p>
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Mainland companies"
              />
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Mainland companies
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  If you're looking to trade within the UAE, or prefer not to be
                  within a free zone, establishing a mainland company is the
                  path.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Free zone companies"
              />
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Free zone companies
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Learn more about Dubai's free zones and the process of setting
                  up a company within them.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Employees, administration and logistics"
              />
              <div className="">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Employees, Regulations, legal compliance and taxation
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Employees, administration and logistics"
              />
              <div className="">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Support for start-ups and SMEs
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Getting the right help is crucial for a new company – and
                  Dubai has plenty of support options.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Employees, administration and logistics"
              />
              <div className="">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Employees, administration and logistics
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  All the information you need about visas, bank accounts,
                  employment, and business premises.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
