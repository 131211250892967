import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import NewFaq from "./FaqTowColumn";
export default function MainLandCompanies() {
  const faqItems = [
    {
      question: "Industrial Licence",
      ans: `This is applicable to all kinds of manufacturing, production, packaging, segregation or collection, including the manufacturing of food, textiles, paper, petroleum products, and equipment and appliances.

NB: After getting the industrial licence you will need to obtain external approvals from relevant government departments in order to begin operations.`,
    },
    {
      question: "E-Trader Licence",
      ans: `If you’re a home-based individual or entrepreneur who sells products and services via social media or other online platforms, this licence is for you.`,
    },
    {
      question: "Commercial Licence",
      ans: `This licence is for you if your business revolves around buying and selling of most goods and services, including import and export, logistics, physical products, software, construction, and real estate.`,
    },
    {
      question: "Dual Licence",
      ans: `Dual licensing allows companies operating in specific free zones to extend their presence to the mainland without the hassle of leasing additional office space or partnering with a local shareholder. This is currently available in collaboration with five free zones`,
    },
    {
      question: "Professional Licence",
      ans: `This type of licence is for individuals who offer professional services based on their intellectual or artistic abilities.`,
    },
  ];
  const legalStrictureFaq = [
    {
      question: "General Partnership",
      ans: `This involves two or more partners who are jointly and separately liable for the company’s obligations using their personal funds.`,
    },
    {
      question: "Public Joint Stock Company",
      ans: `A company with capital divided into equal negotiable shares, where the founders subscribe to a portion of such shares, while the remainder is offered to the public through a public offering.`,
    },
    {
      question: "Limited Partnership",
      ans: `A company formed of one or more general partners who have joint and separate liability, or one or more limited partners who have limited liability proportional to their capital shares.`,
    },
    {
      question: "Civil Company",
      ans: `This is a company owned by two or more partners practising a professional activity such as tailors, beauty salons, consultancy or training, etc.`,
    },
    {
      question: "Limited Liability Company",
      ans: `A business entity in which partners' liability is limited to their capital share. A Limited Liability Company can be established and owned by a UAE national or any other individual or company, regardless of nationality, and can have multiple partners, up to 50.

        A company of this type owned by one person only is known as a Limited Liability Company – Single Ownership. It is subject to the same laws and regulations as limited liability companies with multiple owners.`,
    },
    {
      question: "Sole Proprietorship",
      ans: `A business owned by one person, who is personally liable for all financial obligations and liabilities incurred by the business.`,
    },
    {
      question: "Private Joint Stock Company",
      ans: `A company of this type has up to 200 shareholders with an equal division of capital into nominal value shares. It can be established and owned by one legal person (company), and its shares cannot be publicly offered.

        Where a company of this type is owned by one person only it is known as Private Joint Stock Company – Single Ownership, and the owner's liability for company obligations is limited to the capital specified in its Memorandum of Association. In this case the company's name must include the term "One-Person Private Joint-Stock". It is subject to the same laws and regulations as private joint stock companies with multiple owners.`,
    },
  ];
  const registrationProcess = [
    {
        question: "1: Initial approval",
        ans: `The Dubai Department of Economy & Tourism reviews your trade licence application and documents, and gives initial approval for your business to be established in the jurisdiction. Initial approval allows you to proceed with the next step, such as securing business premises.`,
      },
    {
        question: "2: Reserve your trade name",
        ans: `You must now register your trade name, business activity and company structure; you can also reserve your trade name before the initial approval is granted. Dubai has specific rules to follow for selected a trade name.`,
      },
    {
        question: "3: Receive your business licence",
        ans: `After initial approval, you must:

Submit the initial approval receipt
Submit a duly attested memorandum of association (MoA)
If the company is an LLC, one-person company or a civil company, the DET will issue the MoA
For all other legal forms of company, a Notary can issue the MoA
Apply for and receive approval from other government entities, if required
Submit a duly attested service agent contract (for civil establishments and companies 100% owned by non-GCC nationals) if it involves a local service agent
After completing this process, you will receive your business licence – learn more about this process in the Al Wajeez guide.`,
      },
  ]
  return (
    <>
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Mainland companies
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            If you’re looking to trade within the UAE, or prefer not to be
            within a free zone, establishing a mainland company is the path for
            you.
            <br />
            <br />
            Companies established on the UAE “mainland” - those outside a free
            zone - are free to operate across a range of sectors. They can also
            trade with companies and individuals within Dubai and the UAE, as
            well as internationally.
            <br />
            <br />
            The best part is that you can retain 100% foreign ownership of most
            types of mainland companies, across most activities. However, do
            bear in mind that some companies may require an Emirati partner.
            <br />
            <br />
            If you’re ready to begin this journey, here are the essential steps
            to set up your mainland company:
          </p>
        </div>
      </div>
      <div className="row" style={{ width: "80%", margin: "auto" }}>
        <NewFaq title="Choose your licence" colNo={2} faqItems={faqItems} />
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto 0",
          padding: "10px",
        }}
      ></div>


      <div className="row" style={{ width: "80%", margin: "auto" }}>
        <NewFaq
          title="Choose your legal structure"
          colNo={2}
          faqItems={legalStrictureFaq}
        />

        <div>
          <p>
            You can retain 100% foreign ownership with the following legal
            forms:
          </p>
          <ul>
            <li>General Partnership (2-50 Partners)</li>
            <li>Limited Partnership (2-50 Partners)</li>
            <li>
              Limited Liability Company (Single Ownership / 2-50 Partners)
            </li>
            <li>Private Joint Stock Company (2-50 Partners)</li>
          </ul>
          <p>
            For more information on all legal forms, download the Al Wajeez
            guide.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "auto",
          padding: "50px 50px 0",
         
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "0px 50px",
            color: "#505050",
          }}
        >
          <h3>Gather your essential documents</h3>
          <bold>
            The next step is to fill out your forms and put all the necessary
            documents together.
          </bold>
          <p>
            If you’re new to Dubai, ensure you have a tourist/visit visa – if
            you don't have a valid entry visa, you won't be able to set up a
            business.
          </p>
          <ul>
            <p>
              Then, all new businesses require the following documents for
              initial approval:
            </p>
            <li>
              Copy of the licensee’s passport (if a foreigner) or Emirates ID
              (if Emirati/Resident)
            </li>
            <li>
              Copy of the applicant’s residence permit/visa (for non-GCC
              nationals)
            </li>
            <li>
              The company’s articles of association (not applicable for a
              one-person limited liability company)
            </li>
            <p>
              For public shareholding companies and private joint-stock
              companies, you will also require:
            </p>
            <li>A feasibility study of the project</li>
            <p>Branches of companies based in other emirates also require:</p>
            <li>
              The parent company’s board resolution to open a branch in Dubai
            </li>
            <li>A letter of authorisation from the managing director</li>
            <li>
              A copy of the parent company’s commercial registration certificate
            </li>
            <li>
              A copy of the parent company’s memorandum of association (MoA)
            </li>
            <p>Branches of foreign or free zone companies also require:</p>
            <li>A copy of the parent company’s licence</li>
            <p>
              The documents of the parent company should be attested and
              legalised with the UAE Embassy and Ministry of Foreign Affairs in
              the country of origin, and counter attested with the Ministry of
              Foreign Affairs in the UAE. The documents also need to be legally
              translated in Arabic and attested by the Ministry of Justice in
              the UAE.
            </p>
          </ul>
        </div>
      </div>
      <div className="row" style={{ width: "80%", margin: "auto" }}>
        <NewFaq
          title="Start your company registration process
"
          colNo={2}
          faqItems={registrationProcess}
        />

      
      </div>
      <div className="container my-5">
        <h2 className="mb-4">More from Dubai for business</h2>
        <p className="mb-5">
          Browse information on Dubai's business landscape and how to start or
          move your business to Dubai.
        </p>
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Mainland companies"
              />
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Mainland companies
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  If you're looking to trade within the UAE, or prefer not to be
                  within a free zone, establishing a mainland company is the
                  path.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Free zone companies"
              />
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Free zone companies
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Learn more about Dubai's free zones and the process of setting
                  up a company within them.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Employees, administration and logistics"
              />
              <div className="">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Employees, Regulations, legal compliance and taxation
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Employees, administration and logistics"
              />
              <div className="">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Support for start-ups and SMEs
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Getting the right help is crucial for a new company – and
                  Dubai has plenty of support options.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Employees, administration and logistics"
              />
              <div className="">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Employees, administration and logistics
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  All the information you need about visas, bank accounts,
                  employment, and business premises.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
