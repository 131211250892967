import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
export default function BankAccount() {
  return (
    <>
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Open a bank account in Dubai for personal use
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <p>
            Discover seamless banking solutions and exclusive benefits with a
            Dubai bank account.
          </p>
          <h2 className="resource-section-title">
            Navigate Dubai’s easy banking ecosystem
          </h2>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            A personal bank account is an indispensable tool to living in Dubai,
            be it for making online payments or receiving a monthly salary.
            Setting up an account is easy, and can be accomplished by visiting
            any local bank branch and providing the required documents. It's
            also easy to open a Dubai bank account online.
            <br />
            <br />
            Residents – whether employed by a company or self-employed – can
            choose from local, regional and foreign banks in Dubai. Most have
            branches in all major areas of the city, with an abundance of easily
            accessible ATMs. All banks provide 24/7 online banking as well as
            via proprietary banking apps.
            <br />
            <br />
            Additionally, banks offer a wide range of privileges on debit and
            credit cards. These include discounts at restaurants, the cinema and
            local attractions, or free money transfers, better interest rates on
            loans and more, depending on the bank and the type of account.
          </p>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>How to choose the right bank for you</h3>
          <p>
            {" "}
            Choosing the best bank to open an account in Dubai requires careful
            consideration based on multiple factors and preferences:{" "}
          </p>
          <ul>
            <li>
              Proximity and accessibility of the bank's physical branches and
              ATM locations
            </li>
            <li>
              Account types and associated features, such as minimum balance and
              penalties
            </li>
            <li>
              Fees associated with the account, such as maintenance, ATM usage
              and wire transfers
            </li>
            <li>
              Value-added perks and benefits, including financial planning
              assistance, reward programmes or innovative account features (free
              withdrawals from other bank ATMs and Air Miles, for example)
            </li>
            <li>
              Ability to get personal loans in Dubai without a salary transfer
              to that specific account
            </li>
          </ul>
          <p>
            {" "}
            Most companies already have their preferred banking partners, and
            new accounts for employees will be automatically opened at the same
            bank in order to deposit monthly salaries. However, this does not
            stop individuals from opening personal accounts at other banks.
          </p>
        </div>
      </div>

      <div className="resource-container">
        <h2 className="resource-section-title">
          General applicant requirements
        </h2>

        <div className="resource-card-container">
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F91D;</span>
            </div>

            <p>Applicants must be at least 18 years old to open an account</p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F3E0;</span>
            </div>

            <p>Valid proof of identity, like a passport or Emirates ID</p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F4BB;</span>
            </div>
            <p>Some banks require a minimum salary to open a Dubai account</p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span class="icon">&#128104;&#8205;&#127891;</span>
            </div>
            <p>Most banks require a minimum amount to be maintained</p>
          </div>
        </div>
      </div>
      <div className="resource-container">
        <h2 className="resource-section-title">Documents required</h2>

        <div className="resource-card-container">
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F91D;</span>
            </div>

            <p>Original passport (usually valid for more than a month)</p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F3E0;</span>
            </div>

            <p>UAE residence or investor visa (for non-GCC nationals)</p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F4BB;</span>
            </div>
            <p>
              Emirates ID (or ID application form if it is still in process)
            </p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span class="icon">&#128104;&#8205;&#127891;</span>
            </div>
            <p>Proof of address (tenancy contract or phone/internet bill)</p>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Common types of accounts</h3>
          <ul>
            <li>
              <h5>Current Account</h5>
              <p>
                Ideal for frequent use, including day-to-day bills and expenses,
                ensuring seamless transactions and easy access to funds.
                Usually, this is a zero-interest account but comes with a
                chequebook facility.
              </p>
            </li>
            <li>
              <h5>Savings Account</h5>
              <p>
                Designed for putting aside money and accumulating funds over
                time. Usually, this type of account does not come with a
                chequebook, but deposits can accumulate interest over time.
              </p>
            </li>
            <li>
              <h5>Joint Account</h5>
              <p>
                This is an account usually operated by two individuals, most
                commonly married couples. In the UAE, to open a joint account,
                both individuals need to be physically present in the country
                and have valid residence visas.
              </p>
            </li>
            <li>
              <h5>Salary Transfer</h5>
              <p>
                This is an account to which an employee’s salary gets credited,
                usually opened at the company’s preferred bank. Generally, a
                minimum salary of AED3,000 is required to open a salary account,
                but in general, it is not mandatory to have one.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div className="resource-container">
        <h2 className="resource-section-title">
          Top banking institutions in Dubai
        </h2>
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/common/placeholder-media-do-not-use/default-image/fab-logo.jpg?rev=14444bbafda04e00bef19eb4a4fc9772&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>First Abu Dhabi Bank (FAB)</h4>
            <p>
              The largest bank in the UAE and one of the world’s biggest
              financial institutions.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/common/placeholder-media-do-not-use/default-image/fab-logo.jpg?rev=14444bbafda04e00bef19eb4a4fc9772&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>First Abu Dhabi Bank (FAB)</h4>
            <p>
              The largest bank in the UAE and one of the world’s biggest
              financial institutions.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/common/placeholder-media-do-not-use/default-image/fab-logo.jpg?rev=14444bbafda04e00bef19eb4a4fc9772&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>First Abu Dhabi Bank (FAB)</h4>
            <p>
              The largest bank in the UAE and one of the world’s biggest
              financial institutions.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/common/placeholder-media-do-not-use/default-image/fab-logo.jpg?rev=14444bbafda04e00bef19eb4a4fc9772&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>First Abu Dhabi Bank (FAB)</h4>
            <p>
              The largest bank in the UAE and one of the world’s biggest
              financial institutions.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "80%",
            lineHeight: "28px",
            margin: "30px auto",
            color: "#505050",
          }}
        >
          <h3>Steps to set up your bank account</h3>
          <ul>
            <li>Decide on the bank that suits your needs</li>
            <li>Choose the type of account you want to open</li>
            <li>
              Prepare the necessary documents and fill out the required forms
            </li>
            <li>
              Wait to hear back from the bank with approval or further
              instructions
            </li>
            <li>
              Ensure you follow bank rules and requirements to keep your account
              operational
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
