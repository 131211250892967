import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
export default function FoodAndAgriculture() {
  return (
    <>
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Food and agriculture
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <h2 className="resource-section-title">
            Oasis for food and agri-business
          </h2>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Dubai has long operated as a pivotal trade gateway between the East
            and West. This also extends to food manufacturing, FMCG and
            agri-businesses, particularly with the focus on enabling tech
            innovation to satisfy customers’ evolving demands.
            <br />
            <br />
            Dubai’s advanced digitalisation has also empowered local
            manufacturers and retail giants to launch online platforms,
            capitalising on consumers’ increasing preference for FMCG
            e-commerce. The city’s strong capital deployment, coupled with the
            government’s focus on enhancing food security and building more
            sustainable supply chains, is expected to propel Dubai to become the
            go-to destination for FMCG, farming and food production.
          </p>
        </div>
      </div>
      <div className="resource-container">
        <h2 className="resource-section-title">Trade gateway</h2>
        <div className="resource-card-container">
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F91D;</span>
            </div>
            <h4>Dubai - strategic location</h4>
            <p>
              Ideal location enabling trade of food, agri products and
              commodities globally.
            </p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F3E0;</span>
            </div>
            <h4>Exceptional infrastructure</h4>
            <p>
              Infrastructure and logistics capabilities across land, sea and
              air.
            </p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F4BB;</span>
            </div>
            <h4>Supporting local production</h4>
            <p>
              Strong focus on local production in line with D33 initiative and
              National Food Security Strategy.
            </p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span class="icon">&#128104;&#8205;&#127891;</span>
            </div>
            <h4>Industrial manufacturing</h4>
            <p>
              Foodtech and agritech are key sectors in focus under Operation
              300bn.
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Prioritising sustainability and food security</h3>
          <p>
            Dubai will play a crucial role in achieving the objectives of the
            UAE National Food Security Strategy 2051. This initiative aims to
            eliminate hunger by guaranteeing year-round access to safe, abundant
            and nutritious food. This will be achieved by enabling sustainable
            food production, improving local supply, building strong
            international partnerships, improving nutrition and reducing waste.
            The overall goal is to position the UAE at the top of the Global
            Food Security Index by 2051.
            <br />
            <br />
            Dubai is committed to achieving the status of being the world's most
            food-secure nation by 2071. This goal hinges on Dubai's dedication
            to fostering innovation within agriculture, managing food security,
            promoting organic farming and advancing sustainable production
            practices.
          </p>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Thriving FMCG industry</h3>
          <p>
            Dubai is home to a vibrant FMCG market that leverages the city’s
            strategic location, numerous free zones as well as many warehousing
            and distribution facilities. Key players include Jebel Ali Port
            (Jafza), the largest port in the Middle East and the ninth-largest
            in the world, and the Dubai Multi Commodities Centre (DMCC). They
            offer efficient, secure and innovative transportation and storage
            solutions.
            <br />
            <br />
            Another pivotal enabler is DP World, which is vital in the global
            food trade and nurtures projects like the US$1.5 billion (AED5.5
            billion) Dubai Food Park. It offers comprehensive cold storage
            facilities, collaborates with international logistics companies like
            J&T Express and ensures efficient transport of food items and
            perishable products.
          </p>

          <h4 style={{ margin: "30px 0" }}>
            Empowering local food manufacturing
          </h4>
          <p>
            Dubai is a regional food manufacturing hotspot. Dubai Industrial
            City alone houses 100 entities, including multinational FMCG and
            food manufacturers as well as 67 operational factories.It's
            efficient distribution network is a prominent attractor for food and
            agri-business firms. Another advantage is that the city offers a
            strategic entry point into the fast-growing US$1.5 trillion (AED5.5
            trillion) MEASA consumer spending market. This combination has
            attracted a strong local presence from global FMCG majors like
            Unilever, Procter & Gamble, Nestlé, Coca-Cola, PepsiCo, Johnson &
            Johnson, Mars, Reckitt-Benckiser, Danone and Colgate-Palmolive.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h3>Innovation in agriculture and farming</h3>
        <p>
          Dubai has built up a reputation as a dynamic hub for foodtech and
          agritech innovation. In 2021, the city launched Food Tech Valley, one
          of the world’s first agritech cities. It is projected to triple the
          city’s food production and functions as an integrated modern hub for
          future clean tech-based food and agricultural products. It also serves
          as an incubator for innovators looking to shape the future of the food
          industry.
          <br />
          <br />
          The city also provides extensive funding and mentorship opportunities
          for food and agriculture start-ups. The Mohammed Bin Rashid Innovation
          Fund has funded foodtech and agritech start-ups, and In5, TECOM's
          incubator has supported promising ventures in the sector.
        </p>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h4>550+ companies</h4>
        <p>
          Dubai's population is projected to reach 5.8 million by the end of
          this decade. As a proactive response, the city has unveiled the Dubai
          2040 Urban Master Plan, which promotes sustainable urban development,
          improving the quality of life for residents and boosting Dubai's
          appeal as a leisure and business destination.
        </p>
      </div>
      <div className="row" style={{ width: "80%", margin: "auto" }}>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h3>550+ companies</h3>
            <p style={{ marginBottom: "0px" }}>
              from more than 70 countries operate in the free zone's food and
              agriculture cluster, which occupies over 1.57 million square
              meters.
            </p>
          </div>
        </div>
        <div className="col-6">
          <div
            style={{
              width: "90%",
              background: "#005f66",
              color: "white",
              padding: "40px 35px",
              borderRadius: "15px",
            }}
          >
            <h3>US$43.98 billion</h3>
            <p style={{ marginBottom: "0px" }}>
              estimated value of the UAE’s food service market by 2029.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h4>World capital of gastronomy</h4>
        <p>
          In recent years, Dubai has skyrocketed in popularity as one of the
          world’s top gastronomy hubs, with more than 13,000 restaurants and
          eateries catering to a diverse population from over 200 nations. This
          represents another viable business opportunity for foodpreneurs.
          <br />
          <br />
          The presence of culinary guides such as Gault&Millau and Michelin
          Guide Dubai further underscores the city’s rising prestige as a
          premier culinary destination, excelling in service standards, culinary
          variety, and fine dining options.
        </p>

        <h4 style={{ margin: "30px 0" }}>
          Market-leading food industry events
        </h4>
        <p>
          Dubai hosts Gulfood, the world’s largest food and beverage sourcing
          event, every year. The 2024 edition was the biggest ever, with more
          than 5,500 leading F&B companies, showcasing 150,000+ new products
          from more than 190 countries. The event has proven instrumental in
          fostering collaboration and innovation between key players from the
          public and private sectors. It has also helped orchestrate a shared
          vision to improve the future of the sector, solidifying Dubai’s
          position as a leading food and beverage hub.
          <br />
          <br />
          Dubai also hosts conventions like the Dubai International Food Safety
          Conference, a testament to its commitment to national and global food
          security. The 17th edition took place in December 2023, under the
          theme ‘Impact of Climate Change on Food Safety’. It featured 3,000+
          local, regional and international companies, as well as industry
          experts, researchers and academics. The collective goal was to
          exchange knowledge, innovation and the latest food safety and security
          trends.
        </p>
      </div>
    </>
  );
}
