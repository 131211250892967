import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
import NewFaq from "./FaqTowColumn";

export default function Regulations() {
  const taxFaq = [
    {
      question: "Corporate Tax (CT)",
      ans: `Corporate Tax is a direct tax imposed on the net income or profit generated by corporations and other entities through their business operations. Mainland companies pay 9% Corporate Tax on all net income or profit above AED 375,000 (US$102,000) or more per annum – for more information visit the Federal Tax Authority.

There are some categories of companies which are exempt from Corporate Tax if they meet certain conditions, including extractive businesses, non-extractive natural resource businesses, and qualifying investment funds. If your enterprise falls in these categories, consult the Federal Tax Authority's guidelines or speak to a tax specialist.

Free zone companies are not subject to Corporate Tax.`,
    },
    {
      question: "Customs Duty",
      ans: `Customs duty of 5% is payable on most imports based on the cost, insurance and freight (CIF) invoice value. Higher rates apply to tobacco (100%) and alcohol (50%). Generally, goods do not incur customs duty when placed within free zones – but if they leave the free zone for a destination within the GCC, customs duty is levied on the import at the first entry into the GCC, including the UAE.

Detailed customs duty rules and procedures apply for registration, declaration clearance, temporary admission, duty free allowances, etc. Please contact a tax specialist for more information.`,
    },
    {
      question: "Value Added Tax (VAT)",
      ans: `VAT is a 5% consumption tax imposed on goods and services (with some exemptions), collected and accounted for by businesses at the point of sale on behalf of the government. 

Businesses in the UAE must register for VAT under two circumstances: 

If their taxable supplies and imports exceed AED 375,000 (US$ 102,000) per year, or
If a non-UAE-based business makes taxable supplies in the UAE without another person responsible for paying the due tax. 
VAT registration is optional for businesses with supplies and imports exceeding AED 187,500 (USUS$ 51,000) annually.

All mainland companies are subject to the UAE’s VAT regime.

Some free zones, or areas within free zones, are “Designated Zones”: the sale of goods between two designated zones incurs no VAT, while the sale of goods from these zones to the mainland does incur VAT. 

VAT returns are filed regularly through the Federal Tax Authority's electronic portal within 28 days after the end of the tax period for each type of business. The standard VAT period is quarterly for businesses with an annual turnover below AED 150 million (US$40.8 million) and monthly for businesses with an annual turnover of AED 150 million (US$40.8 million) or more.`,
    },
    {
      question: "Excise Tax",
      ans: `Certain goods have had an excise tax levied on them since 2017, in order to discourage their consumption. The current goods subject to excise tax are:

Tobacco and tobacco products: 100%
Liquids used in electronic smoking devices and tools: 100%
Electronic smoking devices and tools: 100%
Energy drinks: 100%
Carbonated drinks: 50%
Sweetened drinks: 50%
There are more specific sub-categories within each of the above – contact a tax specialist if you will be involved in the sale of any of these goods.`,
    },
    {
      question: "Transfer pricing",
      ans: `Transfer pricing (TP) refers to how goods and services sold from one division or subsidiary of a company to another part of the company are priced.

TP rules in the UAE largely follow the guidance from the OECD TP guidelines. Transactions between related parties must be conducted on an arm’s length basis, in accordance with the methods prescribed under the Corporate Tax law. Organisations that satisfy set thresholds are required to prepare and maintain TP documentation.`,
    },
    {
      question: "Social security",
      ans: `The UAE's social security regime for Emiratis requires contributions from both the employer and the employee. Employers must make a payment equivalent to 12.5% of an Emirati worker's gross remuneration towards the scheme, while the employee must contribute 5% of their remuneration.

Other GCC nationals working in the UAE will also need to follow the social security payment rules of their home countries, which may also require contributions from the employer.`,
    },
    {
      question: "Real Estate Transfer Fees",
      ans: `When a property is transferred from a seller to a buyer, the sale is subject to a 4% Real Estate Transfer Fee (plus an administrative fee), payable to the Dubai Land Department when the transaction is recorded. Typically the buyer pays this fee, but it is common for buyer and seller to negotiate how the fee will be split between them.`,
    },
  ];
  const cortesFaq = [
    {
      question: "Dubai Courts",
      ans: `Dubai Courts is the Dubai government department responsible for managing Dubai’s civil law court system. Dubai Courts also offer amicable settlement services in commercial, civil, and real-estate conflicts, in addition to will-registration and family reform services.

Dubai Courts only uses Arabic for its processes, and provides instant translation services from 21 languages to Arabic and vice-versa, including the most used languages in Dubai: English, Chinese, Urdu, and Russian.`,
    },
    {
      question: "Arbitration centres",
      ans: `Arbitration centres established by the individual emirates’ Chambers of Commerce and Industry also specialise in reconciling commercial disputes before going to the courts. The Dubai International Arbitration Centre (DIAC) is the region’s largest alternate-dispute-settlement centre.`,
    },
    {
      question: "DIFC Courts",
      ans: `Dubai’s other main legal jurisdiction is located within the Dubai International Finance Centre (DIFC) free zone and is independent from the Dubai civil courts. DIFC Courts administer a unique common law, English language jurisdiction specifically designed to govern commercial and civil disputes on an emirate, federal, regional, and global level.

Originally the DIFC Courts were established to hear only cases relating to the DIFC. However, in October 2011, Decree no.16/2011 extended the courts’ remit to hear any civil or commercial case in which both parties select the DIFC Courts’ jurisdiction, either in their original contracts/agreements or during a dispute. 

`,
    },
    {
      question: "Free zone legal dispute settlement",
      ans: `Most free zones offer a mediation service to the companies registered there. If this mediation fails to solve the dispute, the mediation committee issues a letter which allows the concerned companies to take the case to Dubai courts. Please refer to the free zones for more information on dispute settlement.`,
    },
  ];
  return (
    <>
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Regulations, legal compliance and taxation
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <h2 style={{ width: "80%", lineHeight: "28px", marginTop: "20px" }}>
            Beneficial Owners and Anti-money Laundering
          </h2>
          <b>Make your declarations as soon as you set up your company.</b>
          <p style={{ margin: "50px 30px" }}>
            Once your company has its licence, whether in mainland or a free
            zone, it must make an Ultimate Beneficial Owners (UBO) declaration,
            to disclose who controls the company. This is part of the UAE’s
            commitment to prevent money-laundering, and improve financial
            transparency.
            <br />
            <br />
            To fulfil anti money-laundering regulations, financial companies and
            Designated Non-Financial Businesses and Professions (DNFBP) must
            then also register as a reporting entity, stakeholder or supervisory
            body with the UAE Financial Intelligence Unit’s goAML service.
          </p>
        </div>
      </div>

      <div
        style={{
          width: "90%",
          margin: "auto",
          padding: "50px 50px 0",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "0px 50px",
            color: "#505050",
          }}
        >
          <h3>Taxation</h3>
          <b>
            Learn how to comply with the UAE's Corporate Tax and VAT regimes.
          </b>
          <div style={{ margin: "50px 30px" }}>
            <p>
              While Dubai residents are not subject to income tax, companies
              operating from the emirate may be subject to a range of taxes.
              <br />
              <br />
              For assistance and professional advice on your company’s tax
              obligations, you can contact a tax agent: see the Federal Tax
              Authority’s list of registered tax agents to find an agent.
              <br />
              <br />
              You can also get more details on the UAE’s taxes, including all
              rules and regulations, from the Federal Tax Authority.
            </p>
          </div>
          <NewFaq title="" colNo={2} faqItems={taxFaq} />
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "auto",
          padding: "50px 50px 0",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "0px 50px",
            color: "#505050",
          }}
        >
          <h3>Financial reporting and auditing</h3>
          <b>Keep your company accounts and records up to date.</b>
          <div style={{ margin: "50px 30px" }}>
            <p>
              All legal entities doing business in Dubai must keep financial
              records and must obtain an annual audit report which contains all
              information about income and expenses. The auditor's report can be
              submitted in Arabic or English.
              <br />
              <br />
              Mainland and free zones companies must appoint an auditor to
              inspect its books annually. On the mainland, auditors must be
              licensed and registered with the UAE Ministry of Economy.
              Companies are required to keep records of each annual audit for
              five years.
              <br />
              <br />
              Here is an indicative list of some of the top auditing firms
              operating in Dubai
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "auto",
          padding: "50px 50px 0",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "0px 50px",
            color: "#505050",
          }}
        >
          <h3>Copyright and intellectual property rights</h3>
          <b>The UAE has robust protection for IP and trademarks.</b>
          <div style={{ margin: "50px 30px" }}>
            <p>
              In Dubai, intellectual property rights are protected under UAE
              federal law by the UAE Ministry of Economy, and enforced at the
              emirate level by Dubai-based authorities. In 2021 the UAE updated
              its laws to enhance patent, trademark, copyright, industrial
              design and trade secret protections and enforcement mechanisms.
              <br />
              <br />
              That same year the UAE also signed up to the international
              trademark system known as the Madrid System, which means that
              foreign businesses and trademark owners in the country can file a
              single international trademark application and pay one set of fees
              to gain protection in up to 130 countries.
              <br />
              <br />
              See the UAE Ministry of Economy’s website for detals of laws and
              regulations relating to copyright and intellectual property.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "auto",
          padding: "50px 50px 0",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "0px 50px",
            color: "#505050",
          }}
        >
          <h3>Judicial and courts system</h3>
          <b>
            There are several court and arbitration systems available for
            businesses in Dubai.
          </b>
          <div style={{ margin: "50px 30px" }}>
            <p>
            The judicial system in the UAE is a combination of federal and emirate-level courts, which uphold the rule of law, resolve disputes, and each emirate can decide to establish its own judiciary or merge with the federal court system.


              <br />
              <br />
              There are three primary sources of law in Dubai:
            </p>
            <ul>
                <li>All laws and decrees passed at UAE level (Federal Laws): These federal laws cover issues central to the UAE’s national interest, such as foreign affairs, defence, federal finance, security, civil aviation, education and public health</li>
                <li>Laws passed in Dubai that are specific to the emirate: These laws cover any and all local matters that are not the prerogative of the national government.</li>
                <li>Sharia (Islamic law): Sharia serves as the primary foundation for legislation, with the judicial system applying either Sharia or civil law based on the specific circumstances of each case.</li>
            </ul>
            <p>
            Companies based on the mainland have the following options for resolving legal disputes:
            </p>
            <NewFaq title="" colNo={2} faqItems={cortesFaq} />
          </div>
        </div>
      </div>

      <div className="container my-5">
        <h2 className="mb-4">More from Dubai for business</h2>
        <p className="mb-5">
          Browse information on Dubai's business landscape and how to start or
          move your business to Dubai.
        </p>
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Mainland companies"
              />
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Mainland companies
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  If you're looking to trade within the UAE, or prefer not to be
                  within a free zone, establishing a mainland company is the
                  path.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Free zone companies"
              />
              <div className="card-body">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Free zone companies
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Learn more about Dubai's free zones and the process of setting
                  up a company within them.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Employees, administration and logistics"
              />
              <div className="">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Employees, Regulations, legal compliance and taxation
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Employees, administration and logistics"
              />
              <div className="">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Support for start-ups and SMEs
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Getting the right help is crucial for a new company – and
                  Dubai has plenty of support options.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="card h-100">
              <img
                src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-skyline-cityscape-sheikh-zayed-road-dusk-small.jpg?rev=bcbbd4b2da014e7e9501a5217a003fff&cx=0.81&cy=0.43&cw=1020&ch=765"
                className="card-img-top"
                alt="Employees, administration and logistics"
              />
              <div className="">
                <h5
                  className="card-title"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  Employees, administration and logistics
                </h5>
                <p
                  className="card-text"
                  style={{ background: "none", boxShadow: "none" }}
                >
                  All the information you need about visas, bank accounts,
                  employment, and business premises.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
