import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
export default function CityLiving() {
  return (
    <>
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Useful resources for daily life in Dubai
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <p>
            This city prioritises comfort and convenience, with a multitude of
            resources available for every type of service.
          </p>
          <h3>Accessing services made easy</h3>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Residents and visitors alike can benefit from seamless access to
            essential services and information in Dubai – both online and
            offline – be it for healthcare, safety, payments, delivery and more.
            <br />
            <br />
            Every major government service is available via a website, app and
            through physical offices. The same goes for private services across
            a range of sectors, all enabled with contactless payments and
            streamlined customer support.
          </p>
        </div>
      </div>
      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3 style={{ marginTop: "40px" }}>Emergency phone contacts</h3>
          <p>
            Knowing how to get help can make all the difference in an emergency.
            Below are emergency contact numbers that may prove useful in times
            of crisis:
          </p>

          <ul>
            <li>Dubai Police: 999</li>
            <li>Ambulance: 998</li>
            <li>Fire department: 997</li>
            <li>Coast guard: 996</li>
          </ul>
        </div>
      </div>

      <div className="resource-container">
        <h2 style={{ textAlign: "left" }}>Home Life</h2>
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-cityscape-burj-khalifa.jpg?rev=2a081b9c0a4c45299d308249b18c6e52&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Urban Company</h4>
            <p>
              Comprehensive cleaning services, including one-time visits and
              subscriptions, plus booking and scheduling.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/invest-in-dubai/iid-photo-shoot-2023/iid-regulations-header-v2.jpg?rev=92a020047219414c9259ea0505630a46&cx=0.53&cy=0.34&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Justlife</h4>
            <p>
              Offers home cleaning, lab-at-home services, spa treatments,
              disinfection and more, with same-day availability.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/article/h/hotels-close-to-dubai-airport/hotels-close-to-dubai-airport-header-shutterstock-jun-2023.jpg?rev=378d43b7f6804a3490467ad11e6b3279&cx=0.58&cy=0.44&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>ServiceMarket</h4>
            <p>
              This platform connects users with home services like cleaning,
              repairs, moving, pest control and more.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/leisure/campaigns/retire-in-dubai/rid-why-dubai/stay-connected/rid-stay-connected.jpg?rev=a728351efdfb4c818932095546e75d2e&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Washmen</h4>
            <p>
              Specialises in laundry and dry-cleaning services for clothes, home
              linens, shoes and bags, with home pick-up and delivery.
            </p>
          </div>
        </div>
      </div>
      <div className="resource-container">
        <h2 style={{ textAlign: "left" }}>Food Delivery</h2>
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-cityscape-burj-khalifa.jpg?rev=2a081b9c0a4c45299d308249b18c6e52&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Talabat</h4>
            <p>
              Founded in 2004, Talabat has expanded throughout the region. It
              offers food, groceries and medication delivery.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/invest-in-dubai/iid-photo-shoot-2023/iid-regulations-header-v2.jpg?rev=92a020047219414c9259ea0505630a46&cx=0.53&cy=0.34&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Deliveroo</h4>
            <p>
              This popular app partners with restaurants and cafes across the
              city for food delivery and takeaway.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/article/h/hotels-close-to-dubai-airport/hotels-close-to-dubai-airport-header-shutterstock-jun-2023.jpg?rev=378d43b7f6804a3490467ad11e6b3279&cx=0.58&cy=0.44&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Uber Eats</h4>
            <p>
              Part of the global Uber family, this service features major
              restaurants and F&B chains across various cuisines.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/leisure/campaigns/retire-in-dubai/rid-why-dubai/stay-connected/rid-stay-connected.jpg?rev=a728351efdfb4c818932095546e75d2e&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Instashop</h4>
            <p>
              Primarily a grocery delivery app, it also partners with pharmacies
              to deliver everyday needs to the customer's door.
            </p>
          </div>
        </div>
      </div>
      <div className="resource-container">
        <h2 style={{ textAlign: "left" }}>Entertainment and dining</h2>
        <div className="resource-card-container">
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/business-in-dubai/images/dubai-cityscape-burj-khalifa.jpg?rev=2a081b9c0a4c45299d308249b18c6e52&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Dubai Calendar</h4>
            <p>
              Dubai's official events calendar, with an extensive list of
              concerts, festivals, exhibitions and more.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/invest-in-dubai/iid-photo-shoot-2023/iid-regulations-header-v2.jpg?rev=92a020047219414c9259ea0505630a46&cx=0.53&cy=0.34&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Platinumlist</h4>
            <p>
              A renowned ticketing platform which supplies tickets for
              entertainment events, including concerts, sports and nightlife.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/gathercontent/article/h/hotels-close-to-dubai-airport/hotels-close-to-dubai-airport-header-shutterstock-jun-2023.jpg?rev=378d43b7f6804a3490467ad11e6b3279&cx=0.58&cy=0.44&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Zomato</h4>
            <p>
              A comprehensive food and dining discovery app, with detailed
              reviews, menus and local restaurant information.
            </p>
          </div>
          <div className="resource-card">
            <img
              src="https://www.investindubai.gov.ae/-/media/images/leisure/campaigns/retire-in-dubai/rid-why-dubai/stay-connected/rid-stay-connected.jpg?rev=a728351efdfb4c818932095546e75d2e&cx=0.5&cy=0.5&cw=500&ch=375"
              alt="img"
              style={{ maxWidth: "100%" }}
            />

            <h4>Instashop</h4>
            <p>
              Primarily a grocery delivery app, it also partners with pharmacies
              to deliver everyday needs to the customer's door.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
