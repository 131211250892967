import React from "react";
import homeOfficeImg from "../assets/images/home-office.jpg";
export default function Technology() {
  return (
    <>
      <div className="container-xxl " style={{ padding: "7% 7% 0 7%" }}>
        <div>
          <h1
            style={{ marginBottom: "20px", color: "black" }}
            className="display-6"
          >
            Technology and ICT
          </h1>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        <img
          src={homeOfficeImg}
          alt="home office banner"
          width={"100%"}
          style={{ borderBottomRightRadius: "137px" }}
        />
      </div>

      <div
        style={{
          background: "#ececed",
          padding: "30px 0",
          marginBottom: "40px",
          width: "90%",
          float: "inline-end",
          borderBottomLeftRadius: "85px",
        }}
      >
        <div style={{ width: "80%", margin: "auto" }}>
          <p style={{ width: "80%", lineHeight: "28px" }}>
            Dubai nurtures a thriving tech ecosystem, continuously pushing the
            boundaries of innovation in technology and ICT (Information and
            Communications Technology). With its commitment to initiatives like
            the Dubai Economic Agenda (D33), the city aims to become a premier
            hub for tech companies by 2033. Dubai's strategic positioning as a
            gateway to emerging markets in the Middle East, South Asia and
            Africa with a combined GDP of US$7.7 trillion (AED28.28 trillion)
            offers easy access to high-potential economies.
          </p>
        </div>
      </div>
      <div className="resource-container">
        <h2 className="resource-section-title">Strong technology ecosystem</h2>
        <div className="resource-card-container">
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F91D;</span>
            </div>
            <h4>A pro-business economy</h4>
            <p>Low taxes and prime location to global markets.</p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F3E0;</span>
            </div>
            <h4>Outstanding infrastructure</h4>
            <p>24/7 integrated and secure digital services.</p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
              <span className="resource-icon">&#x1F4BB;</span>
            </div>
            <h4>Supportive tech ecosystem</h4>
            <p>Vast network of incubators and accelerators.</p>
          </div>
          <div className="resource-card">
            <div className="resource-icon-circle">
            <span class="icon">&#128104;&#8205;&#127891;</span>
            </div>
            <h4>Highly skilled workforce</h4>
            <p>A workforce that is both talented and diverse.</p>
          </div>
        </div>
      </div>
     

      <div
        style={{
          width: "90%",
          margin: "40px auto",
          padding: "50px",
          borderTopLeftRadius: "70px",
          background: "#d0e4eb",
        }}
      >
        <div
          style={{
            width: "90%",
            lineHeight: "28px",
            margin: "30px 50px",
            color: "#505050",
          }}
        >
          <h3>Global hub for technology</h3>
          <p>
          As the region’s preferred innovation hub, Dubai is home to more than 2,300 technology companies. Digital and tech firms can test and market new products and technologies thanks to initiatives like Sandbox Dubai, a founder-focused start-up programme launched under D33. The sector is enhanced by the presence of global players like Meta, Microsoft, Google, IBM, Apple, Cisco, Tata Consultancy Services and Amazon. The Dubai Chamber of Digital Economy aims to further strengthen this dynamic ecosystem by attracting 300 new start-ups to the city by 2024.
           
          </p>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h3>Forefront of digital transformation
        </h3>
        <p>
        Dubai’s journey to becoming a leading digital destination started over two decades ago. Today, it stands as the most digitally advanced economy in the region and ranks among the world's top 20 cities in terms of digitalisation. For five consecutive years, the UAE has been recognized among the 20 most digitally competitive nations.
        </p>
      </div>
      <div
        style={{
          width: "80%",
          lineHeight: "28px",
          margin: "30px auto",
          color: "#505050",
        }}
      >
        <h3>Future-ready programmes
        </h3>
        <p>
        The Dubai Future Foundation initiative, with its focus on driving innovation, nurturing futuristic projects and advancing technological progress to mould the city's future, acts as a platform facilitating various initiatives such as Dubai Future Labs, the Dubai Robotics and Automation Programme and Dubai 10X.  
        </p>
      </div>
    </>
  );
}
